import { KAIA } from '../types';
export default {
  type: KAIA,
  service: 'kaia.io-ws',
  url: 'wss://public-en.node.kaia.io/ws',
  port: 443,
  auth: false,
  username: '',
  password: ''
};
