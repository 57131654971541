import { FTM } from '../types';
export default {
  type: FTM,
  service: 'fantom.network-ws',
  url: 'wss://fantom-rpc.publicnode.com',
  port: 443,
  auth: false,
  username: '',
  password: ''
};
