import { XLAYER } from '../types';
export default {
  type: XLAYER,
  service: 'okx.com-ws',
  url: 'wss://xlayerws.okx.com',
  port: 443,
  auth: false,
  username: '',
  password: ''
};
