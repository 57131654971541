import { POL } from '../types';
export default {
  type: POL,
  service: 'myetherwallet.com-ws',
  url: 'wss://nodes.mewapi.io/ws/matic',
  port: 443,
  auth: false,
  username: '',
  password: ''
};
